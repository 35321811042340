<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('SupportGisTypeCreate')"
    @edit="(id) => onEdit('SupportGisTypeEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'SupportGisTypes',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'SupportGisTypes',
      tableCaption: 'Типы заявок',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Типы заявок',
        },
      ],
      tableHeaders: [
        { text: 'Наименование', alias: 'name', order: 'name' },
        {
          text: 'Наименование статуса',
          alias: 'statusName',
          order: 'statusName',
        },
        { text: '', alias: 'actions', order: 'actions' },
      ],
    };
  },
};
</script>
